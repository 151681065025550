import {makeStyles} from "@material-ui/core/styles";
import {useDispatch} from "react-redux";
import {closeSnackbar} from "../../store/actions/NotiferActions";
import CloseIcon from "@material-ui/icons/Close";
import {Button} from "@material-ui/core";
import React from "react";
import {SnackbarKey} from "notistack";

const useStyles = makeStyles(theme => ({
    expand: {
        padding: '8px 8px',
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    }
}));

type NotifierCloseProps = {
    msgKey: SnackbarKey
}

export const NotifierClose = (props: NotifierCloseProps) => {
    const styles = useStyles();
    const dispatch = useDispatch();

    return <Button className={styles.expand} onClick={() => dispatch(closeSnackbar(props.msgKey))}><CloseIcon/></Button>
}